<template>
  <section>
    <q-btn
      v-if="product.isInStore || (!product.isInStock && availableInStore)"
      :to="path"
      label="Pick Up Today"
      color="primary"
      square
      outline
    />
    <q-btn
      v-else-if="product.isVariable"
      :to="path"
      label="See Options"
      color="primary"
      flat
      square
      @click="select()"
    />
    <q-btn
      v-else-if="!product.isInStock && product.orderType == 'Notify'"
      label="Get Notified"
      color="primary"
      padding="sm md"
      square
      outline
      @click="notify()"
    />
    <q-btn
      v-else-if="!product.isInStock"
      color="negative"
      label="Out Of Stock"
      flat
      square
      disable
    />
    <q-btn
      v-else-if="product.mapPolicy == 'Email'"
      :label="
        product.style.startsWith('SIG-') ? 'Email For Price' : 'Get Quote'
      "
      color="primary"
      padding="sm md"
      square
      outline
      @click="quote()"
    />
    <q-btn
      v-else
      :icon="cartItem ? mdiCartCheck : mdiCartPlus"
      :loading="add.status.value == 'pending'"
      :disable="cartStore.pending"
      :color="cartItem ? 'positive' : 'primary'"
      :label="cartItem ? `In Cart (${cartItem.quantity})` : 'Add To Cart'"
      padding="sm md"
      square
      @click="add.execute()"
    />
  </section>
</template>

<script lang="ts" setup>
import { mdiCartCheck, mdiCartPlus } from '@quasar/extras/mdi-v7';
import type { Product } from '~/types/search';

const props = defineProps({
  product: {
    type: Object as PropType<Product>,
    required: true,
  },
});

const cartStore = useCartStore();
const { notify, add, quote, select, path, availableInStore } = useCatalog(props.product);

const cartItem = computed(() =>
  cartStore.data?.items.find(i => i.productId == props.product.id),
);
</script>
